const defaultConfig = {
  env: process.env.REACT_APP_ENV || 'development',
  merchantApiHost: 'https://merchant.api.quicksell.co',
};

const developmentConfig = {
  apiHost: 'https://api.dev.quicksell.co',
  googleClientId: '1031733541585-otpmdn4sf0ctcui80q006qau0jj5h2rc',
  imageUrlSource: {
    CLOUD_FRONT: 'https://daz11igyyg6m8.cloudfront.net',
    S3: 'https://quicksales-staging.s3.ap-south-1.amazonaws.com',
    CDN: 'https://daz11igyyg6m8.cloudfront.net',
    CDN_CATALOGUE: 'https://catalogue-cdn.quicksell.co/s/',
    sources: [
      'https://daz11igyyg6m8.cloudfront.net',
      'https://quicksales-staging.s3.ap-south-1.amazonaws.com'
    ]
  },
  merchantApiHost: 'https://public-api.dev.quicksell.co/',
};

const apiStagingConfig = {
  apiHost: 'https://api-staging.quicksell.co',
  googleClientId: '223646330354-n60bgmrlna5dc1h4tjtosqu4n4v1m1tq',
  imageUrlSource: {
    CLOUD_FRONT: 'https://do9uy4stciz2v.cloudfront.net',
    S3: 'https://s3.amazonaws.com/quicksales',
    CDN: 'https://cdn.quicksell.co',
    sources: ['https://do9uy4stciz2v.cloudfront.net', 'https://s3.amazonaws.com/quicksales'],
    CDN_CATALOGUE: 'https://catalogue-cdn.quicksell.co/s/'
  },
};

const apiStaging2Config = {
  apiHost: 'https://api-staging-3.quicksell.co',
  googleClientId: '223646330354-n60bgmrlna5dc1h4tjtosqu4n4v1m1tq',
  imageUrlSource: {
    CLOUD_FRONT: 'https://do9uy4stciz2v.cloudfront.net',
    S3: 'https://s3.amazonaws.com/quicksales',
    CDN: 'https://cdn.quicksell.co',
    sources: ['https://do9uy4stciz2v.cloudfront.net', 'https://s3.amazonaws.com/quicksales'],
    CDN_CATALOGUE: 'https://catalogue-cdn.quicksell.co/s/'
  },
};

const productionConfig = {
  apiHost: 'https://api.quicksell.co',
  googleClientId: '223646330354-n60bgmrlna5dc1h4tjtosqu4n4v1m1tq',
  imageUrlSource: {
    CLOUD_FRONT: 'https://do9uy4stciz2v.cloudfront.net',
    S3: 'https://s3.amazonaws.com/quicksales',
    CDN: 'https://cdn.quicksell.co',
    sources: ['https://do9uy4stciz2v.cloudfront.net', 'https://s3.amazonaws.com/quicksales'],
    CDN_CATALOGUE: 'https://catalogue-cdn.quicksell.co/s/'
  },
};

let config;
if (defaultConfig.env === 'production') {
  config = { ...defaultConfig, ...productionConfig };
} else if (defaultConfig.env === 'api-staging') {
  config = { ...defaultConfig, ...apiStagingConfig };
} else if (defaultConfig.env === 'api-staging-2') {
  config = { ...defaultConfig, ...apiStaging2Config };
} else {
  config = { ...defaultConfig, ...developmentConfig };
}

export default config;
